figure {
  overflow: hidden;
}

figure:hover img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#veneer-edit figure {
  margin: 0;
}
