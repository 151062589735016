
$baseColor: #a9a9a9;
$backGroundColor: white;

/* Handle */
::-webkit-scrollbar-thumb {
    background: $baseColor; 
    border-radius: 0;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2c3e50; 
  }
  
  
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: $baseColor $backGroundColor;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: $backGroundColor;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: $baseColor;
    border-radius: 0;
    // border: 1px solid $backGroundColor;
  }
  