.veneer-picker {
    .thumbs-container {
      display: flex;
      // justify-content: space-between !important;
      flex-wrap: wrap;
      max-height: 50vh;
      overflow: scroll;
    }
    .veneer-thumb {
      padding: 0.2em;
      margin-top: 1em;
      cursor: pointer;
      border: 1px solid white;
      width:12.5%;
  
      &.active {
        border: 1px solid #777;
      }
  
    }
    .section-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 0.25em;
    }
  
    img {
      width: 100%;
      max-width: 350px;
      max-height: 350px;
      height: auto;
      display: block;
    }
  
  }