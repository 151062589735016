@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;800&family=Poppins:wght@200;400;700&display=swap');

#Guest {

  font-family: 'Inter';
  color: black;

  .page-wrapper {
    flex: 1 0 auto;
  }


  /*Global*/
  @font-face {
    font-family: 'Futura Medium';
    src: local('Futura Medium'),
      url('./../../fonts/futura-medium-bt.ttf') format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'futura light bt';
    src: local('futura light bt'),
      url('./../../fonts/futura-light-bt.ttf') format('truetype');
    font-weight: bold;
  }

  img {
    display: block;
  }

  h1,
  h2,
  h3 {
    font-weight: 400;
  }

  p {
    font-size: 0.8rem;
    margin-bottom: 1em;
    font-weight: 400;
  }

  ul {
    font-size: 0.9rem;
    list-style: none;
    font-weight: 400;
  }

  a {
    color: #777;
    text-decoration: none;
  }

  .mobile-txt {
    display: none !important;
  }

  .inner {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .align-start {
    align-items: flex-start;
  }

  .end {
    justify-content: flex-end;
  }

  #output {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.75);
  }

  #output img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    display: block;
  }

  #output p {
    position: absolute;
    top: 40px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-block;
    background: #222;
    color: #fff;
    padding: 5px 10px;
    text-align: center;
    z-index: 120;
  }

  #output span {
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-block;
    background: #a9a9a9;
    color: #fff;
    padding: 5px 10px;
    text-align: center;
    z-index: 120;
    cursor: pointer;
  }


  /*Intro*/
  .intro {
    /* background: url('/img/intro-bg.jpg') center center no-repeat; */
    background-size: cover;
    text-align: center;
    padding: 2.5em 2em 0;
    min-height: 84vh;
  }

  .intro h1 {
    color: #000;
    font-size: 2rem;
    margin: 0 0 0.8em;
  }

  .intro h1 span {
    font-weight: 500;
    display: block;
    width: 100%;
    font-size: 0.9rem;
  }

  .rooms {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 3em auto;
  }

  .room {
    flex: 1 0;
    border: 4px solid #a9a9a9;
    margin: 0 1em;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
  }

  .room img {
    width: 100%;
    height: auto;
  }

  .room span {
    display: block;
    width: 100%;
    padding: 4px;
    background: #a9a9a9;
    color: #fff;
  }

  .room:hover {
    transition: 0.2s;
    border: 4px solid #222;
  }

  .room:hover span {
    transition: 0.2s;
    background: #222;
  }


  /*Footer*/
  .footer {
    position: fixed;
    z-index: 30;
    bottom: 0;
    width: 100%;
    background: #939d9e;
    padding: 0.5em 1em;
    overflow: auto;
  }

  .footer a {
    font-size: 0.8rem;
    color: #fff;
    text-decoration: none;
    margin-left: 3%;
  }

  .footer a:hover {
    color: #eee;
    text-decoration: underline;
  }

  #disclaimer {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #222;
    color: #fff;
    padding: 2em;
    text-align: center;
    z-index: 100;
  }

  #disclaimer span {
    display: table;
    background: #a9a9a9;
    color: #fff;
    margin: 1.5em auto 0 auto;
    padding: 0.8em;
    cursor: pointer;
  }

  /*Visualiser*/
  .tabs input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .tabs {
    overflow: hidden;
  }

  .tab {
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #ccc;
  }

  .tab:first-of-type {
    border-top: none;
  }

  .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 0.8em 0;
    cursor: pointer;
    font-size: 1.1rem;
    /* Icon */
  }

  .tab-label::after {
    content: '+';
    color: #a9a9a9;
    width: auto;
    height: 1em;
    text-align: center;
    transition: all 0.1s;
    font-size: 1.5rem;
  }

  .tab-content {
    max-height: 0;
    color: #2c3e50;
    background: white;
    transition: all 0.1s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .tab-content>* {
    direction: ltr;
  }

  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
  }

  .tab.active .tab-label::after {
    content: '-';
  }

  .tab.active .tab-content {
    max-height: 100%;
    padding-bottom: 1em;
  }

  .sidebar {
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 350px;
    padding: 1em 2em;
    z-index: 20;
  }

  .extended {
    width: 800px !important;
  }

  img.extend {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .rotate {
    -webkit-transform: translateY(-50%) rotate(180deg) !important;
    transform: translateY(-50%) rotate(180deg) !important;
  }


  ul.categories {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }

  /* buttons */
  ul.categories li {
    cursor: pointer;
    flex: 1;
    max-width: 120px;
    text-align: center;
    background-color: #272727;
    border: 3px solid #272727;
    margin: 0 0.2em 1.5em 0.2em;
    padding: 0.6em 1em;
    font-size: 0.9rem;
    font-weight: 700;
    color: white;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  ul.categories li:hover {
    background-color: transparent;
    color: #272727;
  }

  ul.categories li:first-of-type {
    margin-left: 0;
  }

  ul.categories li:last-of-type {
    margin-right: 0;
  }

  ul.categories li.selected {
    background-color: transparent;
    color: #272727;
  }

  .btn {
    cursor: pointer;
    text-align: center;
    border: 3px;
    border-style: solid;
    margin: 0 0.2em 1.5em 0.2em;
    padding: 0.6em 1em;
    font-size: 0.9rem;
    font-weight: 700;

    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .btn-black {
    background-color: #272727;
    border-color: #272727;
    color: white;
  }

  .btn-black:hover {
    background-color: transparent;
    color: rgba(39, 39, 39, 0.7);
  }

  // Menu list items
  .menuItems {
    direction: rtl;
    overflow: auto;
    padding-left: 10px;
    max-height: 40vh;
  }

  .menuItems>div {
    display: flex;
    flex-wrap: wrap;
    direction: ltr;
    flex-direction: column;
  }

  .menuItems .button,
  .menuItems button {
    border: 0;
    width: 75px;
    height: 75px;
    margin: 1%;
    border: 1px solid #eee;
    cursor: pointer;
  }

  .menuItems .standard {
    cursor: pointer;
  }

  .menuItems .button img {
    width: 100%;
    height: auto;
    margin: 0 auto 0.3em auto;
  }

  .menuItems .button span {
    display: block;
    font-size: 0.55rem;
    margin-bottom: 1.5em;
  }

  .menuItems .button p {
    font-size: 0.6rem;
    font-weight: 600;
    margin: 0 0 2em 0;
    padding: 0;
    line-height: 1;
  }


  // ---
  .short {
    max-height: 25vh !important;
  }


  .list-block {
    position: relative;
    padding-top: 2em;
  }

  .list-block::before {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.8rem;
    font-weight: 600;
  }

  // room options
  .option-with-img {
    .thumbs-container {
      display: flex;
      justify-content: space-between !important;
      flex-wrap: wrap;
      max-height: 30vh;
      overflow: scroll;
    }

    .veneer-thumb {
      padding: 0.2em;
      cursor: pointer;
      border: 1px solid white;
      width: 33%;

      &.active {
        border: 1px solid #777;
      }

    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

  }



  // ---

  // --- room view
  .room-container {
    .room-img {
      display: flex;
      flex-direction: row-reverse;

      .magnify-container {
        height: 100%;
      }

      img {
        height: 100%;
      }
    }

    .room-actions {
      z-index: 999;
      position: absolute;
      bottom: 1rem;
      right: 25vw;
    }

  }

  // ---
  @media (min-width: 769px) {
    .room-container {
      .room-img {
        height: 100vh;
      }
    }
  }

  @media (min-height: 769px) {

    .short {
      max-height: 22vh !important;
    }
  }

  @media (min-height: 900px) {
    .short {
      max-height: 25vh !important;
    }
  }

  @media (max-width: 1100px) {}

  @media (max-width: 600px) {
    .room-container .room-img img {
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 1200px) {}

  @media (min-width: 1900px) and (min-height: 900px) {}

  @media (max-width: 800px) {
    .desktop-txt {
      display: none !important;
    }

    .mobile-txt {
      display: block !important;
    }

    .intro::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
    }

    .intro>* {
      position: relative;
    }

    .rooms {
      display: block !important;
    }

    .room {
      margin-bottom: 1em;
    }

    .sidebar {
      position: relative;
      top: auto;
      left: auto;
      bottom: 0;
      width: 100%;
    }

    a.return {
      margin-bottom: 0.8em;
    }

    #doorList .button {
      width: 100px;
    }

    #doorList .button p {
      font-size: 0.7rem;
    }

    #doorList .button span {
      font-size: 0.5rem;
    }

    img.extend {
      display: none;
    }

    #room {
      margin: 0;
    }

    .change_room {
      display: none;
    }

    .suppliers {
      width: 90%;
      padding: 1em;
    }

    .footer {
      position: relative;
      bottom: auto;
    }
  }


  // image loading spinner
  .room-spinner {
    text-align: center;
    padding-left: 33vw;
    padding-top: 50vh;
  }

  // dropdown
  /* actual dropdown animation */
  .dropdown {
    .dropdown-menu {
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;

      // max-height: 0;
      display: none;
      overflow: hidden;
      opacity: 0;
      z-index: -999;
    }
  }

  .show {
    .dropdown-menu {
      display: block;
      opacity: 1 !important;
      width: 100%;
      z-index: 100;

      a:active {
        background: none !important;
      }
    }
  }

  // sidebar --------------------------
  #sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 200px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 99;

    @media (max-width: 769px) {
      position: relative;
    }

    @media (min-width: 1920px) {
    }

    .logo {
      // background-color: #000;
      margin: 0 auto;
      margin-bottom: 1em;
      padding: 10px 0;

      img {
        border: none;
        margin: 0 auto;
      }
    }

    .logo-sub {
      text-align: center;
      display: none;
    }


    a.return {
      display: block;
      font-size: 0.9rem;
    }

    a.return span {
      display: inline-block;
      color: #a9a9a9;
      font-size: 1rem;
      margin-right: 0.75em;
    }

    .room-options {
      padding: 0 2em;
    }
  }

  // helpers --------------------------
  .separator-helper {
    border: 1px solid rgb(239, 239, 239);
    margin: 2em auto;
  }

  .mt-70 {
    margin-top: 70px;
  }

  .mt-80 {
    margin-top: 80px;
  }

  .mt-90 {
    margin-top: 90px;
  }

  .mt-100 {
    margin-top: 100px;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  // card hover overlay --------------------------
  .card-hover {
    .card-img-overlay {
      transition: opacity .3s ease-in-out;
      opacity: 0;
      z-index: 5;
    }

    .card-img-overlay:hover {
      transition: opacity .3s ease-in-out;
      opacity: 0.5;
    }
  }

  // bubble-bg --------------------------
  .bubble-bg {
    height: 80vw;
    width: 80vw;
    background-color: #f2f2f2;
    position: fixed;
    left: -10vw;
    top: -35vw;
    z-index: -1;
    border-radius: 50%;
  }

  // footer bubble
  .bubble-parent {
    position: relative;
    z-index: 0;

    .bubble {
      position: absolute;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  .nav-fadeOut {
    transition: all .2s;
    opacity: 0;
  }

  .nav-fadeIn {
    transition: all .2s;
    opacity: 1;
  }


  // theme2 --------------------------
  .navbar-brand {
    margin: auto;
  }

  .navbar-links {
    margin-top: .5rem;
    justify-content: space-between !important;
  }

  .main {
    .home-intro {
      min-height: 40vh;

      .display-1 {
        font-size: 3rem;
      }
    }

    .tx-1 {
      font-size: 1.5rem;
    }
  }

  .room-actions {
    display: none;
  }

  footer .newsletter-form {
    input {
      border: 'none';
      padding: .1em .5em;
      width: 100vw;
      background-color: 'black';
      border-bottom: '1px solid white';
      color: 'white';
      margin: 0;
      height: 43px;
    }
  }

  @media (min-width: 769px) {

    .navbar-brand {
      margin: 0;
    }

    .navbar-links {
      flex-direction: row-reverse !important;
      justify-content: flex-start !important;
    }

    .main {

      .home-intro {
        min-height: 30vh;

        .display-1 {
          font-size: 6rem;
        }
      }

      .tx-1 {
        font-size: 1rem;
      }

    }


    .room-actions {
      display: flex;
    }


    footer .newsletter-form {
      input {
        border: 'none';
        padding: '.1em .5em';
        width: '50vw';
        background-color: 'black';
        border-bottom: '1px solid white';
        color: 'white';
        margin: '0';
        height: '43px';
      }
    }

  }

}